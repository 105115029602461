import React, { useEffect, useState } from "react";
import { Route, Switch } from 'react-router-dom';
import { getForum } from "../api";
import Topics from '../topic/Topics';
import TopicList from "../topic/TopicList";
import Topic from "../topic/Topic";

function Forum ({ match }) {
    const [forum, setForum] = useState({});
    const [topics, setTopics] = useState([]);
    useEffect(() => {
        (async () => {
            const { forum, topics } = await getForum(match.params.forumId);
            setForum(forum);
            setTopics(topics);
        })();
    }, [match.params.forumId]);

    return (
        <div className="forum">
            <div className="forum-category">{forum.category}</div>
            <h1>{forum.title}</h1>
            <Switch>
                <Route path={`/forums/:forumId/topics/:topicId`} component={Topic} />
                <Route path="/forums/:forumId" render={() => <TopicList topics={topics} />} />
            </Switch>
        </div>
    )

}
/*
class Forum extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forum: {},
            topics: [],
        };
    }

    async componentWillMount() {
        await this.fetchForum(this.props.forumId)
    }

    async fetchForum(id) {
        const { forum, topics } = await getForum(id);
        this.setState({
            ...this.state,
            forum,
            topics,
        });
    }

    render() {
        const { forum } = this.state;
        const topics = this.state.topics.map(({ forum_title, ...topic}) => topic);
        return (
            <div className="forum">
                <div className="forum-category">{forum.category}</div>
                <h1>{forum.title}</h1>
                <TopicList topics={topics} />
            </div>
        )
    }

}
*/
export default Forum;