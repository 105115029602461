import { useEffect } from "react";
import { getCredentials } from "../api";

function Login (props) {
    console.log('Login.js');
    useEffect(() => {
        async function login() {
            const match = props.location.search.match(/code=([0-9a-z-]+)/);
            const code = match && match[1]
            if (!code) {
                throw new Error(`Invalid authorization code`);
            }
            await Promise.all([
                getCredentials(code),
                new Promise(resolve => setTimeout(resolve, 1500)),
            ]);
            props.history.replace('/');
        }
        login();
    });
    return (
        <div>Authorizing...</div>
    )
}

export default Login;