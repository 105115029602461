import { Link } from 'react-router-dom';
import './TopicListItem.css'

const TopicListItem = (props) =>
<div className="topic-item">
        <a href={`#${props.id}`} />
        <div className="topic-item-title">
            <Link to={`/forums/${props.forum_id}/topics/${props.id}`}>{props.title}</Link>
        </div>
        <span className="topic-item-author">{props.author}</span>
        <div className="topic-item-meta">
            <span className="topic-num-posts">{props.num_posts} posts</span>
            <span className="topic-last-date">{new Date(props.last_post_date).toLocaleString()}</span>
        </div>
        <div className="topic-item-cat-title">{props.category_title}</div>
    </div>

export default TopicListItem;