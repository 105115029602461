import React, { useEffect, useState } from "react";
import { getTopic } from "../api";
import PostList from "../post/PostList";
import './Topic.css';

function Topic (props) {

    const [topic, setTopic] = useState({});
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function getData() {
            const res = await getTopic(props.match.params.topicId);
            setTopic(res.topic);
            setPosts(res.posts.map(({ topic_title, ...post}) => post));
        }
        getData();
    }, [props.match.params.topicId]);

    useEffect(() => {
        if (window.location.hash) {
            const el = document.getElementById(window.location.hash.replace('#', ''));
            if (el) {
                el.scrollIntoView();
            }
        }
    });

    return (
        <div className="topic">
            <h2>{topic.title}</h2>
            <PostList posts={posts} />
        </div>
    )

}

export default Topic;