import { withRouter } from "react-router-dom";
import PostListItem from "./PostListItem";

const ListItemWithRouter = withRouter(PostListItem);

const PostList = ({ posts }) => {
    const postEls = posts.map(p => <li key={p.id}>
        <ListItemWithRouter
            user={p.user}
            body={p.body}
            timestamp={p.created_at}
            topic_title={p.topic_title}
            topic_id={p.topic_id}
            forum_id={p.forum_id}
            id={p.id}
        />
    </li>);
    return (
        <ul className="post-list">
            {postEls}
        </ul>
    );
}

export default PostList;
