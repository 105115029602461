import { withRouter } from "react-router-dom";
import TopicListItem from "./TopicListItem";

const ListItemWithRouter = withRouter(TopicListItem);

const TopicList = ({ topics }) => {
    const topicEls = topics.map(t => <li key={t.id}>
        {<ListItemWithRouter
            author={t.author}
            title={t.title}
            id={t.id}
            category_title={t.category_title}
            num_posts={t.num_posts}
            first_post_date={t.first_post_date}
            last_post_date={t.last_post_date}
            forum_id={t.forum_id}
        />}
    </li>);
    return (
        <ul className="topic-list">
            {topicEls}
        </ul>
    );
}

export default TopicList;
