import { Link } from 'react-router-dom';
import { Converter } from 'showdown';
import './PostListItem.css'

const converter = new Converter();

const ts = timestamp => new Date(timestamp).toLocaleString();

const PostListItem = (props) =>
    <div className="post" id={`post${props.id}`}>
        <div className="post-timestamp">{ts(props.timestamp)}</div>
        {props.topic_title && <div className="post-topic-title"><Link to={`/forums/${props.forum_id}/topics/${props.topic_id}#post${props.id}`}>{props.topic_title}</Link></div>}
        <div className="post-title">{props.title}</div>
        <div className="post-user">{props.user}</div>
        <div className="post-body" dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.body) }}></div>
    </div>

export default PostListItem;
