import React from 'react';
import './App.css';
// import 'mini.css/dist/mini-nord.css';
import Search from './search/Search';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Topics from './topic/Topics';
import Forums from './forum/Forums';
import Forum from './forum/Forum';
import SearchForm from './search/SearchForm';
import Login from './login/Login';
import logo from './logo-192x192.png';

function App () {

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <SearchForm />
          <nav>
            <Link to="/">
              Forums
            </Link>
          </nav>
        </header>
        <main className="app-body">
            <Switch>
              <Route path="/search/:str" component={Search}/>
              <Route path="/forums/:forumId" component={Forum} />
              <Route path="/login" component={Login} />
              <Route path="/" component={Forums}/>
            </Switch>
        </main>
      </Router>
    </div>
  );

}

export default App;
