import axios from "axios";
import qs from 'qs';
import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession
} from 'amazon-cognito-identity-js';

const POOL_DOMAIN = 'https://elbil.auth.eu-north-1.amazoncognito.com';
const POOL_CLIENT_ID = '7tnblr7cqcltp8covnvne4j0m0';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    timeout: 1000,
    headers: { 'Content-Type': 'application/json' }
});

client.interceptors.request.use(
    async (config) => {
        let session = '';
        try {
            session = await getCurrentSession();
        } catch (err) {
            // no op
        }
        config.headers.Authorization = session && session.getAccessToken().getJwtToken();
        return config;
    },
    async (err) => {
        throw err;
    }
);

client.interceptors.response.use(
    async successResponse => {
        return successResponse;
    },
    async err => {
        if (err.response && err.response.status === 401) {
            window.location.href = `${POOL_DOMAIN}/login?client_id=7tnblr7cqcltp8covnvne4j0m0&response_type=code&scope=email+openid&redirect_uri=${window.location.origin}/login`
        }
        return err;
    }
)

const userPool = new CognitoUserPool({
    UserPoolId: 'eu-north-1_koDDeTUe4',
    ClientId: POOL_CLIENT_ID,
});

export async function sendSearchRequest(entity, str) {
    const url = `/search/${entity}/${encodeURIComponent(str)}`;
    const res = await client.get(url);
    return res.data;
}

export async function getTopic(topicId) {
    const [topicReq, postsReq] = await Promise.all([
        client.get(`/topics/${topicId}`),
        client.get(`/topics/${topicId}/posts`),
    ]);
    return {
        topic: topicReq.data.topic,
        posts: postsReq.data.posts,
    };
}

export async function getForum(forumId) {
    const [forumReq, topicReq] = await Promise.all([
        client.get(`/forums/${forumId}`),
        client.get(`/topics?forumId=${forumId}`),
    ]);
    return {
        forum: forumReq.data.forum,
        topics: topicReq.data.topics,
    };
}

export async function getForums() {
    const res = await client.get(`/forums`);
    return {
        forums: res.data.forums,
    };
}

export async function getCurrentSession() {
    return new Promise((resolve, reject) => {
        const curUser = userPool.getCurrentUser();
        if (curUser) {
            curUser.getSession((err, sess) => {
                if (err) reject(err);
                resolve(sess);
            });
        } else {
            reject(new Error(`No active user`));
        }
    })
}

export async function getCredentials(code) {
    try {
        const curSess = await getCurrentSession();
        return curSess;
    } catch (err) {
        const res = await axios.post(`${POOL_DOMAIN}/oauth2/token`, qs.stringify({
            grant_type: 'authorization_code',
            code,
            client_id: POOL_CLIENT_ID,
            redirect_uri: `${window.location.origin}/login`
        }));
        const idToken = new CognitoIdToken({ IdToken: res.data.id_token });
        const username = idToken.decodePayload()['cognito:username'];
        const user = new CognitoUser({
            Username: username,
            Pool: userPool,
        });
        const session = new CognitoUserSession({
            IdToken: idToken,
            AccessToken: new CognitoAccessToken({ AccessToken: res.data.access_token }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: res.data.refresh_token }),
        });
        user.setSignInUserSession(session);
        return session;
    }
}