import React from "react";
import { useHistory, useLocation } from "react-router-dom";
/*
class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.state = {
            searchString: '',
        };
    }

    handleInput(event) {
        this.setState({
            searchString: event.target.value,
        });
    }

    render() {
        return (
            <div className="search-form">
                <input type="text" name="search" value={this.state.searchString} onChange={this.handleInput} />
                {this.state.searchString}
            </div>
            
        );
    }
}
*/

function SearchForm (props) {
    let timeout;
    const history = useHistory();
    const location = useLocation();
    console.log(location);
    function debounce(event) {
        if (props.timout && props.search) {
            clearTimeout(timeout);
            const val = event.target.value;
            if (val && val.length > 3) {
                timeout = setTimeout(() => props.search(event.target.value), 500);
            }
        }
    }
    function submit(event) {
        event.preventDefault();
        const { value } = event.target.querySelector('input[type=\'text\']');
        if (props.search) {
            props.search(value);
        } else {
            history.push(`/search/${value}`);
        }
    }
    function getInputVal() {
        const match = location.pathname.match(/\/search\/(.*)$/);
        return match && match[1] ? match[1] : '';
    }
    return (
        <div className="search-form">
            <form onSubmit={submit} style={{background: 'transparent', outline: 'none', border: 'none'}}>
                <input type="text" defaultValue={getInputVal()} placeholder="Search"  name="search" onChange={debounce} />
                <button type="submit">🔍</button>
            </form>
        </div>
    );
}

export default SearchForm;
