import { Route, Switch, useRouteMatch } from "react-router-dom";
import Topic from "./Topic";
import TopicList from "./TopicList";

function Topics (props) {
    const match = useRouteMatch();
    return (
        <div className="topics">
            <Switch>
                <Route path={`${match.path}/:topicId`} component={Topic}/>
                <Route path={`${match.path}`} component={TopicList}/>
            </Switch>
        </div>
    );
}

export default Topics;