import React, { useEffect, useState } from "react";
import './Search.css';
import { sendSearchRequest } from "../api";
import TopicList from "../topic/TopicList";
import PostList from "../post/PostList";

function Search({ match }) {
    const [posts, setPosts] = useState([]);
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(false);

    const { str } = match.params;
    useEffect(() => {
        (async () => {
            setLoading(true);
            setPosts([]);
            setTopics([]);
            const postReq = await sendSearchRequest('posts', str);
            const topicReq = await sendSearchRequest('topics', str);
            console.log(topicReq, 'topics');
            console.log(postReq, 'posts');
            setPosts(postReq.posts);
            setTopics(topicReq.topics);
            setLoading(false);
        })();
    }, [str]);

    return (
        <section className="search">
            {loading && <div>Loading...</div>}
            <div className={`search-result-wrapper ${loading ? 'hidden' : 'visible'}`}>
                <div className="search-result-list">
                    <div className="topic-result">
                        <h2>Topics</h2>
                        {topics.length === 0 && <div>Nothing found</div>}
                        <TopicList topics={topics}/>
                    </div>
                    <div className="post-result">
                        <h2>Posts</h2>
                        {posts.length === 0 && <div>Nothing found</div>}
                        <PostList posts={posts}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

/*
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            posts: [],
        }
        this.search = this.search.bind(this);
        this.reset = this.reset.bind(this);
        this.handlePostClick = this.handlePostClick.bind(this);
    }

    async search(str) {
        const postReq = await sendSearchRequest('posts', str);
        const topicReq = await sendSearchRequest('topics', str);
        this.setState({
            ...this.state,
            posts: postReq.posts,
            topics: topicReq.topics,
        });
    }

    componentDidUpdate({ location }) {
        if (location !== this.props.location) {
            this.reset();
        }
    }

    reset() {
        this.setState({
            posts: [],
            topics: [],
        });
    }

    handlePostClick(e) {
        console.log(e);
    }

    render() {
        const len = this.state.posts.length + this.state.topics.length;
        return (
            <div className="search">
                <div className={`search-result-wrapper ${len > 0 ? 'visible' : 'hidden'}`}>
                    <div className="search-result-list">
                        <button className="close" onClick={this.reset}>X</button>
                        <div className="topic-result">
                            <h2>Topics</h2>
                            <TopicList topics={this.state.topics}/>
                        </div>
                        <div className="post-result">
                            <h2>Posts</h2>
                            <PostList posts={this.state.posts}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
*/

export default Search;