import { Link } from "react-router-dom";
import './ForumList.css'

function ForumList ({ forums }) {
    console.log(forums);
    const byCat = forums.reduce((acc, f) => {
        acc[f.category] = [
            ...(acc[f.category] || []),
            f,
        ];
        return acc;
    }, {});
    const els = Object.entries(byCat).sort((a, b) => b[1].length - a[1].length).map(([cat, fs]) => <div key={cat} className="forum-list-wrapper">
        { cat && <h3>{cat}</h3> }
        <ul className="forum-list">
            {fs.map(f => <li key={f.id}><Link to={`/forums/${f.id}`}>{f.title}</Link></li>)}
        </ul>
    </div>);
    return (
        <div className="forums">
            {els}
        </div>
    );
}

export default ForumList;