import { useEffect, useState } from "react";
import { getForums } from "../api";
import ForumList from "./ForumList";

function Forums() {
    const [forums, setForums] = useState([]);

    useEffect(() => {
        (async () => {
            const { forums } = await getForums();
            setForums(forums);
        })();
    }, []);

    return (
        <div className="forums">
            <ForumList forums={forums} />
        </div>
    )
}

export default Forums;